// Body
$body-bg: #ffffff;
// Typography
$font-family-sans-serif: 'Nunito',
sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
// Colors
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$primary: #00A0D8 !default;
$secondary: #FF8300 !default;
$success: #03cc9a !default;
$info: #107AA1 !default;
$warning: #f4b325 !default;
$danger: #ED1848 !default;
$theme-colors: ("primary":#00ADEE, "secondary":#FD8204, "success":#03cc9a, "danger":#ED1848, "warning": #f4b325, "info": #107AA1);